module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K67VCDB","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/locales","languages":["en","it"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Simpa.","short_name":"Simpa.","icons":[{"src":"/android-chrome-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/android-chrome-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/android-chrome-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/android-chrome-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/android-chrome-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/android-chrome-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"theme_color":"#27ae60","background_color":"#27ae60","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
