require("./src/styles/main.scss")

exports.onRouteUpdate = ({ location }) => {
  const envs = ["production"]
  if (!envs.includes(process.env.NODE_ENV)) return null

  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
  const sendPageView = () => {
    const pagePath = location ? location.pathname + location.search + location.hash : undefined

    if (typeof window.ga !== "undefined") {
      window.ga("set", "page", pagePath)
      window.ga("send", "pageview")
    }
  }

  const delay = 32
  setTimeout(sendPageView, delay)

  return null
}
